import axios from "axios";
import { get, pick } from "lodash";
import client from "../../../feathers";

export default async function (data = {}) {
  if (!get(data, "username") || !get(data, "password")) {
    return new Promise((resolve, reject) => {
      reject({
        message: "Please input valid data!",
      });
    });
  }

  const passFields = ["username", "password"];

  data = pick(data, passFields);

  return await axios
    .post(`${client.io.io.uri}custom-authentication`, {
      strategy: "local",
      ...data,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return new Promise((resolve, reject) => {
        reject({
          error: err,
          message: "Invalid Login!",
        });
      });
    });
}
