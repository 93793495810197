import {
  Modal
} from "antd";
import { map } from "lodash";
import React, { useEffect, useState } from "react";
import MediaQuery from "react-responsive";
import { useTranslation } from "../../../locales/useTranslation";
import { languages } from "../../../utilities/profile";

//let displayRobot = [];

// markup
let timeoutFunc;
const IncomeCapacity = (props) => {
  // const location = useLocation();
  const {changeLanguage } = useTranslation();
  const [visible, setVisible] = useState(false);
  //let profile = get(props.user.user, "profile");
  // let mainUser = get(props, "user");

  // console.log(props);

  useEffect(() => {
    setVisible(props.visible === true);
  }, [props.visible]);

  function onClickLanguage(value) {
    console.log("value", value);
    // localStorage.setItem("locale", value);
    changeLanguage(value);
  }

  function close() {
    props.onClose();
    setVisible(false);
  }

  const _renderModalContent = () => {
    return (
      <div
        className="lightgreybox-bg-color  pb-10 pt-5 rounded-3xl"
        // style={{ borderRadius: 15 }}
      >
        {map(languages, (item, index) => {
          return (
            <div className=" flex justify-center ">
              <div
                className=" flex justify-center p-2 m-4 touchbox-bg-color"
                style={{ width: 200, cursor: "pointer" }}
                onClick={() => {
                  onClickLanguage(item.value);
                }}
              >
                <span className="" style={{ color: "#fcd535" }}>
                  {item.title}
                  {/* <a
                  onClick={() => {
                    //forgotPasswordClick();
                  }}
                  style={{ color: "#fcd535" }}
                >
                  
                </a> */}
                </span>
              </div>
            </div>
          );
        })}

        {/* <div className="flex justify-around mt-10">
          <span
            className="second-button-color border  py-2 text-center  rounded-3xl cursor-pointer mx-4"
            style={{ width: "100%" }}
            onClick={() => {
              //navigate(routes.home.to());
              props.onClose();
              setVisible(false);
            }}
          >
            {t("close")}
          </span>
          <span
            className="rounded-3xl main-button-color text-center  py-2 cursor-pointer mx-4"
            onClick={() => {
              //   submitPassword();
            }}
            style={{ width: "100%" }}
          >
            {t("confirm")}
          </span>
        </div> */}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="">
        <MediaQuery maxWidth={590}>
          <Modal
            className="no-padding-modal-body modal-body-background-transparent"
            centered
            // maskClosable
            width="70%"
            mask
            footer={null}
            closable={false}
            visible={visible}
            style={{ borderRadius: "10px" }}
            onCancel={() => {
              close();
            }}
          >
            {_renderModalContent()}
          </Modal>
        </MediaQuery>
        <MediaQuery minWidth={591}>
          <Modal
            className="no-padding-modal-body modal-body-background-transparent"
            centered
            // maskClosable
            width="50%"
            mask
            footer={null}
            closable={false}
            visible={visible}
            onCancel={() => {
              close();
            }}
            style={{ borderRadius: "10px" }}
          >
            {_renderModalContent()}
          </Modal>
        </MediaQuery>
      </div>
    </React.Fragment>
  );
};

export default IncomeCapacity;
