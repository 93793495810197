import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useLocation } from "@reach/router";
import { Button, Form, Input, message, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import { Link } from "gatsby";
import localStorage from "local-storage";
import { toLower } from "lodash";
import { set } from "lodash";
import forEach from "lodash.foreach";
import get from "lodash.get";
import React, { useState } from "react";
import { connect } from "react-redux";
import client from "../../../../feathers";
import loginAPI from "../../../api/authentication/loginAPI";
import { newLogoImg } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import { triggerModal } from "../../../redux/actions/app-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { mainColor } from "../../../styles/config";
import Layout from "../../general/components/Layout";
import SelectLanguageModal from "../../setting/components/SelectLanguageModal";

// markup
const LoginPage = (props) => {
  const location = useLocation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [selectVisible, setSelectVisible] = useState(false);
  const [showError, setShowError] = useState();
  const { t } = useTranslation();

  function submitLogin() {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        setLoginLoading(true);
        loginAPI(values)
          .then((res) => {
            console.log("res", res);
            let accessKey = get(res, "data.accessKey");
            let timestamp = get(res, "data.timestamp");

            axios
              .get(`${client.io.io.uri}api/profile/getProfiles`, {
                params: {
                  userId: get(res, "data.user._id"),
                  limit: 1,
                },
                headers: {
                  Authorization: "Bearer " + accessKey,
                },
              })
              .then((res1) => {
                localStorage.set("accessKey", accessKey);
                let profile_data = get(res1, "data.data[0]");
                set(res, "data.user.profile", profile_data);
                message.success(t("loggingIn"));
                props.loginSuccessful(get(res, "data.user"), accessKey,timestamp);
                window.location.assign("/");
                // console.log(res);
                setLoginLoading(false);

              
              })
              .catch((err) => {
                console.log(err);
                setLoginLoading(false);
                message.error(err.message);
              });

            
          })
          .catch((err) => {
            setLoginLoading(false);
            message.error(err.message);
          });
      })
      .catch((err) => {
        setLoginLoading(false);
        forEach(get(err, "errorFields"), (item) => {
          message.error(get(item, "errors[0]"));
        });
      });
  }

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      submitLogin();
    }
  };

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          {/* <NavHeader showBack={false}> */}
          <div className="">
            <div className="relative">
              <img
                src="https://img.freepik.com/free-photo/robot-hand-side-view-background-presenting-technology-gesture_53876-129764.jpg?w=2000"
                style={{
                  width: "100%",
                  height: "300px",
                  filter: "blur(0.5px) brightness(0.5)",
                }}
              />
              <div
                className="  px-5 flex justify-center"
                // style={{
                //   bottom: "50%",
                // }}
              >
                <div className="absolute" style={{ bottom: "53%" }}>
                  <img
                    src={newLogoImg}
                    style={{ width: "81px", height: "80px" }}
                  />
                  <div
                    className="flex justify-center pt-1"
                    style={{
                      fontSize: "20px",
                      lineHeight: "30px",
                      fontWeight: 600,
                    }}
                  >
                    IndoITS
                  </div>
                </div>
                <div className="absolute" style={{ bottom: "15%" }}>
                  <div
                    className="flex justify-center pt-4"
                    style={{
                      fontSize: "20px",
                      lineHeight: "30px",
                      fontWeight: 600,
                    }}
                  >
                    {t("demo", sourceKey.profile)}
                  </div>
                </div>
              </div>
            </div>

            <div className=" px-6">
              <div className="font-semibold text-base flex justify-between">
                {/* 登入 Sign In */}
                <div>{t("signIn")}</div>
                <div
                  className="underline cursor-pointer"
                  style={{ color: mainColor }}
                  onClick={() => {
                    setSelectVisible(true);
                  }}
                >
                  {t("language", sourceKey.profile)}
                </div>
              </div>
              <Form form={form} style={{ color: "white" }}>
                <div className="mt-4 mb-2">
                  {/* 邮件 */}
                  {/* <br></br> */}
                  {t("username")} :
                </div>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: (
                        <React.Fragment>{t("usernameRequired")}</React.Fragment>
                      ),
                    },
                    {
                      validator: (_, value) => {
                        form.setFieldsValue({
                          username: toLower(value?.trim()),
                        });
                        return Promise.resolve();
                      },
                    },
                    // {
                    //   type: "email",
                    //   message: (
                    //     <React.Fragment>
                    //       {/* 用户邮籍形式不正确
                    //           <br /> */}
                    //       {t("invalidEmail")}
                    //     </React.Fragment>
                    //   ),
                    // },
                  ]}
                >
                  <Input
                    placeholder={t("usernameRequired")}
                    className={"input-border"}
                    onKeyPress={handleKeypress}
                  />
                </Form.Item>

                <div className="mt-5 mb-2">
                  {/* 密码
                      <br></br> */}
                  {t("password")} :
                </div>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: (
                        <React.Fragment>
                          {/* 请填写密码
                              <br /> */}
                          {t("passwordRequired")}
                        </React.Fragment>
                      ),
                    },
                  ]}
                >
                  <Input.Password
                    placeholder={t("insertPassword")}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    className={"input-border"}
                    onKeyPress={handleKeypress}
                  />
                </Form.Item>
              </Form>
              <div className="flex justify-center items-center mt-8">
                <Button
                  className="main-button-color button-padding "
                  // shape="round"
                  loading={loginLoading}
                  onClick={(e) => {
                    submitLogin();
                  }}
                  style={{ width: "100%", borderRadius: "40px" }}
                >
                  {t("login")}
                </Button>
                {/* <Button
                    shape="round"
                    className="secondary-button-color"
                    onClick={(e) => {
                      if (typeof window !== `undefined`) {
                        navigate(routes.register.to());
                      }
                    }}
                  >
                    注册 REGISTER
                  </Button> */}
              </div>
            </div>

            <Link to="https://indoits.com/">
              <div
                className="flex justify-center items-center mt-5 underline "
                style={{ color: mainColor }}
              >
                {t("backtoMyits")}
              </div>
            </Link>
            {/* <div className="flex justify-center items-center mt-3">
                <Button
                  icon={<AndroidOutlined style={{ fontSize: "20px" }} />}
                  className="main-button-color "
                  shape="round"
                  onClick={(e) => {
                    window.location.href = apkLink;
                  }}
                >
                  {" "}
                  ANDROID
                </Button>
                
              </div> */}
          </div>
          {/* </NavHeader> */}
        </Spin>
        <SelectLanguageModal
          visible={selectVisible}
          onClose={() => {
            setSelectVisible(false);
          }}
        />
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
